<script setup lang="ts">
import type { PropType, Component } from '@vue/runtime-core';
const attrs = useAttrs();
const props = defineProps({
  tag: {
    type: [String, Object] as PropType<string | Component>,
    default: 'a',
  },
});
</script>

<template>
  <component :is="props.tag" class="cta" v-bind="attrs">
    <slot />
  </component>
</template>

<style scoped lang="scss">
@import '@/assets/css/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.cta {
  display: inline-block;
  border-radius: 10px;
  border: 0;
  background-color: $primary;
  color: white;
  padding: 1em 3em;
  margin-right: 20px;
  margin-bottom: 20px;

  &:hover,
  &:focus {
    background-color: darken($primary, 5%);
  }

  @include media-breakpoint-down(sm) {
    padding: 0.7em 2em;
  }
}
</style>
